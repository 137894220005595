<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="85px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="内容标题：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item label="内容类型：" prop="is_demand">
          <el-select v-model="is_demand" placeholder="请选择">
            <el-option v-for="item in DemandTypeOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容分类：" prop="category_id">
          <el-select v-model="category_id" placeholder="请选择">
            <el-option v-for="item in CategoryOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="付费状态：" prop="pay_type">
          <el-select v-model="pay_type" placeholder="请选择">
            <el-option v-for="item in PayTypeOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { CategoryList } from "../../api/member/member-detail";
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      DemandTypeOptions: [
        { name: "供给", id: 0 },
        { name: "需求", id: 1 },
      ],
      CategoryOptions: [],
      PayTypeOptions: [
        { name: "免费", id: 0 },
        { name: "金额", id: 1 },
        { name: "积分", id: 2 },
      ],
    };
  },
  methods: {
    // 获取分类列表
    getCategoryList() {
      CategoryList({ is_demand: -1 })
        .then((res) => {
          this.CategoryOptions = res.data;
        })
        .catch((err) => {});
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    this.getCategoryList();
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    is_demand: {
      get() {
        return this.form.is_demand == -1 ? "" : this.form.is_demand;
      },
      set(val) {
        this.form.is_demand = val;
      },
    },
    category_id: {
      get() {
        return this.form.category_id == -1 ? "" : this.form.category_id;
      },
      set(val) {
        this.form.category_id = val;
      },
    },
    pay_type: {
      get() {
        return this.form.pay_type == -1 ? "" : this.form.pay_type;
      },
      set(val) {
        this.form.pay_type = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>